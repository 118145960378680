@import '~bootstrap/scss/bootstrap.scss';
@import "./colors.scss";

@font-face {
  font-family: 'Trade Gothic LT Light';
  src: local('Trade Gothic LT Light'), url('../public/Trade Gothic LT Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Trade Gothic LT Bold';
  src: local('Trade Gothic LT Bold'), url('../public/Trade Gothic LT Bold.ttf') format('truetype');
}

.app {
}

#logo {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
  text-decoration: none;
  color: black;
  display: block;
}

#sixte {
  font-family: Trade Gothic LT Bold, Arial, serif;
  position: absolute;
  top: 27px;
  font-size: 22px;
  left: -10px;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

#logo-bar {
  height: 10px;
  background-color: black;
}

#logo-right {
  margin-left: 30px;
}

#de-mijolla {
  font-family: Trade Gothic LT Bold, Arial, serif;
  font-size: 22px;
  display: flex;
  justify-content: space-between;
}

#issambres {
  font-family: Trade Gothic LT Light, Arial, serif;
}

#navbar {
  display: flex;
  background-color: #F3F2EE;
  border-bottom: 1px solid gray;
}

#menu-web {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Trade Gothic LT Bold, Arial, serif;
  text-transform: uppercase;
  display: flex;
}

.menu-divider {
  display: flex;
  align-items: center;
}

.menu-item {
  margin-left: 7px;
  margin-right: 7px;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.menu-item:hover {
  color: gray;
}

.menu-vertical-bar {
  height: 40px;
  width: 1px;
  background-color: gray;
}

.pages-container {
  margin: 10px 0 10px 0;
}

#on-motion {
  background-color: black;
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-family: Trade Gothic LT Bold, Arial, serif;
  height: 36px;
  padding-top: 5px;
}

.on-motion-circle {
  color: darkgray;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
}

.on-motion-circle:hover {
  color: gray;
}

.on-motion-circle-selected {
  color: $app-gray;
  cursor: auto;
}

.on-motion-circle:hover {
  color: $app-gray;
}

#on-motion-navigator {
  background-color: rgba(137, 137, 137, 0.5);
  display: flex;
  height: 36px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.on-motion-item {
  cursor: pointer;
  background-size: contain;
  height: 50vh;
  background-repeat: no-repeat;
  background-position: center center;
  color: white;
  animation: fadeIn linear 1s;
  -webkit-animation: fadeIn linear 1s;
  -moz-animation: fadeIn linear 1s;
  -o-animation: fadeIn linear 1s;
  -ms-animation: fadeIn linear 1s;
}

.on-motion-background {
  cursor: pointer;
  height: 70vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  animation: fadeIn linear 1s;
  -webkit-animation: fadeIn linear 1s;
  -moz-animation: fadeIn linear 1s;
  -o-animation: fadeIn linear 1s;
  -ms-animation: fadeIn linear 1s;
}

.on-motion-text {
  color: white;
  padding: 30px;
  background-color: rgba(230, 175, 9, .8);
  font-family: Trade Gothic LT Bold, Arial, serif;
  text-transform: uppercase;
  width: 50%;
  margin-right: 20px;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.articles-bar {
  display: flex;
  margin-top: 15px;
  margin-bottom: 10px;
  font-family: Trade Gothic LT Bold, Arial, serif;
  text-transform: uppercase;
  color: white;
}

.article-button {
  margin-left: 5px;
  margin-right: 5px;
  background-color: $app-light-gray;
  cursor: pointer;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  flex: 1;
  text-align: center;
  text-decoration: none;
  color: white;
}

.article-button:hover {
  background-color: $app-gray;
  color: white;
}

.painting-item {
  display: flex;
  margin-top: 30px;
}

.painting-item-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.painting-item-right {
  margin-left: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.painting-image {
  width: 100%;
}

.painting-title {
  background-color: $app-very-light-gray;
  text-transform: uppercase;
  text-align: center;
  padding: 10px;
  height: 70px;
  font-family: Trade Gothic LT Light, Arial, serif;
}

.painting-item-text {
  font-family: Trade Gothic LT Light, Arial, serif;
  font-size: 14px;
  margin-right: 10px;
}

.painting-text-subtitle {
  font-family: Trade Gothic LT Bold, Arial, serif;
  font-size: 14px;
  margin-right: 10px;
  text-transform: uppercase;
}

.painting-container {
  max-width: 400px;
  display: flex;
  flex-direction: column;

}

@media (max-width: 800px) {

  .painting-item-left {
    margin-bottom: 20px;
    align-items: flex-start;
  }

  .painting-item {
    flex-direction: column;
  }

  .painting-item-text {
    margin-right: 0;
  }

  .painting-text-subtitle {
    margin-right: 0;
  }

  .painting-item-right {
    margin-left: 0;
  }

}

hr {
  color: transparent;
}

#painting-dogana {
  display: flex;
  justify-content: space-between;
}

.dogana-item {
  width: 30%;
}

.portrait-item {
  width: 45%;
}

.triptique-item {
  height: 200px;
}

#footer {
  background-color: $app-black;
  margin-top: 30px;
  color: #9999;
  font-family: Trade Gothic LT Light, Arial, serif;
}

#footer-container {
  padding-top: 30px;
  padding-bottom: 30px;
}

.copyrights {
  font-size: 14px;
  text-align: center;
}

.footer-title {

}

.footer-text {
  line-height: 30px;
  color: #CCCC;
}

.footer-paragraph {
  line-height: 25px;
  color: #CCCC;
}


.footer-title {
  color: white;
  font-family: Trade Gothic LT Bold, Arial, serif;
  text-transform: uppercase;
  font-size: 16px;
}

#mobile-menu {
  display: none;
}

.mobile-menu-item {
  text-decoration: none;
  cursor: pointer;
  color: black;
  text-transform: uppercase;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.mobile-menu-item:hover {
  color: gray;
}

.menu-horizontal-bar {
  border-bottom: 1px solid $app-very-light-gray;
}

.menu-dropdown-container {
  position: absolute;
  width: 100%;
  display: none;
  z-index: 1000;
}

@media (max-width: 600px) {

  #menu-web {
    display: none !important;
  }

  .menu-dropdown-container {
    display: flex;
  }

  .menu-bars {
    font-size: 30px;
    cursor: pointer;
  }

  #mobile-menu {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }

}


@media (max-width: 1400px) {

  #menu-web {
    flex-direction: column !important;
  }

}

.menu-item-selected {
  color: gray;
  margin-left: 7px;
  margin-right: 7px;
  cursor: pointer;
  text-decoration: none;
}

.menu-item-selected:hover {
  color: gray;
  cursor: unset;
}

#articles {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.article-line-main {
  display: flex;
  justify-content: space-between;
}

.article-line {
  display: flex;
}

.article-subline {
  display: flex;
  width: 50%;
  align-items: flex-start;
}

.article-main {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  text-decoration: none;
}

.article {
  flex: 1;
  display: flex;
  flex-direction: column;
  aspect-ratio: 1 / 1;
  width: 98%;
  margin-left: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.article:hover {
  box-shadow: 10px 5px 5px lightgray;
}

//.article-main:hover {
//  box-shadow: 5px 5px 5px lightgray;
//}

.article-picture {
  flex: 3;
  width: 100%;
  background-size: cover;
}

.article-text {
  text-decoration: none;
  color: black;
  background-color: $app-very-light-gray;
  text-transform: uppercase;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  height: 70px;
  font-family: Trade Gothic LT Light, Arial, serif;
}

.article-text:hover {
  color: black;
}

.article-square {
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: space-between;
}

.article-square-line {
  display: flex;
}

.bottom-article {
  margin-bottom: 20px;
}

.menu-dropdown {
  width: 100%;
  background-color: white;
  padding: 10px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $app-light-gray;
}

@media (max-width: 800px) {

  .article-line-main {
    flex-direction: column;
  }

  .article-line {
    flex-direction: column;
  }

  .article-square {
    margin-left: 0px;
    margin-top: 20px;
    width: 100%;
  }

  .article-main {
    width: 100%;
  }

  .bottom-article {
    margin-bottom: 20px;
  }

  .article-square-line {
    margin-left: 0;
  }

  .main-left-article {
    margin-left: 0;
  }

  .article-main div {
    margin-bottom: 0;
  }

  .article-subline {
    width: 100%;
  }

}

.left-article {
  margin-left: 0;
}

.galiano div {
  background-position-y: bottom;
}

.custom-link {
  text-decoration: none;
  color: rgba(153, 153, 153, 0.6);
  font-weight: bold;
}

.custom-link:hover {
  color: rgba(153, 153, 153, 0.8);
  text-decoration: underline;
}

#on-motion-container {
  display: flex;
  justify-content: center;
}

#on-motion-sub-container {
  //max-width: 700px;
  flex: 1;
}

#root {
  background-color: #fffefd;
}

#sixte-video {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

#mail-to {
  text-decoration: none;
  color: lightgrey;
}

#mail-to:hover {
  text-decoration: underline;
}